import { DarkMode, LightMode, useColorModeValue } from "@chakra-ui/color-mode"
import { Box, Divider, Flex, VStack } from "@chakra-ui/layout"
import React, { ReactNode } from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Kvs, Kv } from "../../components/Kvs"
import { useChakraBase16 } from "../../components/useChakraBase16"

export function ColorKv({ k, v = "–" }: { k: ReactNode; v?: string }) {
  return (
    <>
      <Box fontWeight="bold">{k}</Box>
      <Box as="dd" w="sm" h="2em" bg={v} />
    </>
  )
}

function Colors() {
  const color = useColorModeValue("black", "white")
  const border = useColorModeValue("gray.100", "gray.700")
  const bg = useColorModeValue("white", "gray.800")
  const theme = useChakraBase16()

  return (
    <Box bg={bg} color={color} p={4} borderWidth={1} borderColor={border}>
      <Kvs>
        <Kv k="scheme" v={theme.scheme} />
        <Kv k="author" v={theme.author} />
      </Kvs>
      <Box h={4} />
      <Divider />
      <Box h={4} />
      <VStack spacing={1} align="start">
        <ColorKv k="base00" v={theme.base00} />
        <ColorKv k="base01" v={theme.base01} />
        <ColorKv k="base02" v={theme.base02} />
        <ColorKv k="base03" v={theme.base03} />
        <ColorKv k="base04" v={theme.base04} />
        <ColorKv k="base05" v={theme.base05} />
        <ColorKv k="base06" v={theme.base06} />
        <ColorKv k="base07" v={theme.base07} />
        <ColorKv k="base08" v={theme.base08} />
        <ColorKv k="base09" v={theme.base09} />
        <ColorKv k="base0A" v={theme.base0A} />
        <ColorKv k="base0B" v={theme.base0B} />
        <ColorKv k="base0C" v={theme.base0C} />
        <ColorKv k="base0D" v={theme.base0D} />
        <ColorKv k="base0E" v={theme.base0E} />
        <ColorKv k="base0F" v={theme.base0F} />
      </VStack>
    </Box>
  )
}

export default function Base16Page() {
  return (
    <DebugLayout title="base16">
      {/* {keys(theme).map(key => (
        <div id={key}>{key}</div>
      ))} */}
      <Flex wrap="wrap">
        <LightMode>
          <Colors />
        </LightMode>
        <DarkMode>
          <Colors />
        </DarkMode>
      </Flex>
    </DebugLayout>
  )
}
